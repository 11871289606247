
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, languageOptions } from '@/types/global'

import {
    computed,
    defineComponent,
    Emitter,
    inject,
    LoginData,
    LoginType,
    onBeforeMount,
    onBeforeUnmount,
    ref,
    ResponseData
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { useCapchaImage } from '@/composables/useCapchaImage'
import {
    IUser,
    ILock,
    ILayer,
    ICall,
    ISheildCheck,
    IReload,
    IArrowL,
    IService,
    iconArrowDown
} from '@/components/icons'
import { login } from '@/api'

export default defineComponent({
    name: ROUTES.LOGIN,
    components: {
        IUser,
        ILock,
        ILayer,
        ICall,
        ISheildCheck,
        IReload,
        IArrowL,
        IService,
        'icon-arrow-down': iconArrowDown
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t, locale } = useI18n()
        const store = useStore()
        const router = useRouter()
        const errorMessage = ref<string>('')
        const pwd = ref<string>('')
        const uname = ref<string>('')
        const capchaInput = ref<string>('')
        const agreed = ref<boolean>(false)
        const showLoginType = ref<boolean>(false)
        const phoneNumber = ref<string>('')

        const langArr = ref(languageOptions)
        const showLanguageOptions = ref(false)
        let cs = 0

        const loginTypesArray = ref<LoginType[]>([
            { id: 1, name: 'phonemode' },
            { id: 2, name: 'commandmode' }
        ])

        const loginType = ref<LoginType>({ id: 2, name: 'commandmode' })

        const { getImage, capchaSrc, headerToken, isLoadingCapcha } =
            useCapchaImage()

        getImage()

        onBeforeMount(() => {
            if (lang.value && lang.value !== '') locale.value = lang.value

            emitter.on(EventBuss.AGREE_TO_AGREEMENT, onAgree)

            if (serverLine.value <= 0 && serverLine.value !== -1) {
                router.push({ name: 'select-server-line' })
                return
            }
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.AGREE_TO_AGREEMENT, onAgree)
        })

        const onAgree = (_val: boolean) => {
            agreed.value = _val
        }

        const contactService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }
        const doLogin = () => {
            // validate username
            if (uname.value.trim().length === 0) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('login.usernameerror'))
                return
            }
            // validate password
            if (pwd.value.trim().length === 0) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('login.passworderror'))
                return
            }

            if (loginType.value.id === 1) {
                if (phoneNumber.value.trim().length === 0) {
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('login.phoneerror'))
                    return
                }
            }

            // check capcha

            if (capchaInput.value.toString().trim().length < 4) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('login.codeerror'))
                return
            }

            // check user agreement

            errorMessage.value = ''

            const _prams: any = {
                params: {
                    userName: uname.value,
                    password: pwd.value,
                    verifyCode: capchaInput.value,
                    commandType: loginType.value.id
                },
                token: headerToken.value
            }
            if (loginType.value.id === 1) {
                _prams.params.phone = phoneNumber.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            login(_prams)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const loginData = response.data as ResponseData<LoginData>
                    if (loginData && loginData.status === 200) {
                        const { token, amount, code, id, commandType } =
                            loginData.data!.userInfo!

                        const userSession = {
                            token,
                            username: code,
                            notice: '',
                            point: amount,
                            memberId: id,
                            isFree: false,
                            commandType
                        }
                        store.commit(AppMutation.INIT_USER_SESSION, userSession)
                        store.commit(
                            AppMutation.SET_USER_INFO,
                            loginData.data?.userInfo
                        )
                        emitter.emit(EventBuss.SOCKET_CONNECT)

                        router.push({ name: ROUTES.LOBY })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            loginData.description
                        )
                        getImage()
                    }
                })
                .catch((e) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('common.networkerror')
                    )
                })
        }

        const selectLanguage = (str: string) => {
            store.commit(AppMutation.SET_LANG, str)
            locale.value = str
            showLanguageOptions.value = false
        }

        const showLanguage = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'lang'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showAgreeBox = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'agree'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const hideShowLoginType = () => {
            showLoginType.value = false
        }

        const onSelectLoginType = (_loginType: LoginType) => {
            loginType.value = _loginType
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    capchaInput.value.toString()
                ) {
                    return
                }

                if (
                    capchaInput.value.toString().length > 3 &&
                    event.key.toLowerCase() !== 'backspace'
                ) {
                    event.preventDefault()
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter' && agreed.value) {
                doLogin()
            }
        }

        const doTrylogin = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'demo'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const language = computed((): string => {
            const result = langArr.value.find((x) => x.code === lang.value)
            return result?.name || ''
        })
        const lang = computed((): string => store.getters['lang'])
        const serverLine = computed((): number => store.getters['serverLine'])
        const ipvnotice = computed(() => store.getters['ipvnotice'])
        const filteredLoginType = computed(() =>
            loginTypesArray.value.filter((i) => i.id !== loginType.value.id)
        )

        return {
            errorMessage,
            pwd,
            uname,
            capchaInput,
            lang,
            isLoadingCapcha,
            capchaSrc,
            agreed,
            showLoginType,
            loginTypesArray,
            loginType,
            filteredLoginType,
            phoneNumber,
            langArr,
            showLanguageOptions,
            language,
            selectLanguage,
            onCapchaKeydown,
            hideShowLoginType,
            showLanguage,
            doLogin,
            t,
            getImage,
            showAgreeBox,
            onSelectLoginType,
            doTrylogin,
            contactService,
            ipvnotice
        }
    }
})
